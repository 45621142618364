import React from 'react';
import { SliderCard, TextAreaInput } from 'smart-react';
import { Field } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import './DataField.scss';
/**
 * Used to populate the Slider Card Content based on the provided action
 */
const DataField = ({ handleSlide, editorField, setFormData, formData }) => {
  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  return (
    <SliderCard
      handleSlide={handleSlide}
      title={editorField?.title}
      className='data-field-slider'
    >
      <SliderCard.Body>
        <div className='k-d-flex single-field-row single-row-60'>
          <div className={`field-wrapper field-wrapper-100 data-field-wrapper`}>
            <Field
              key={editorField?.name}
              id={editorField?.name}
              name={editorField?.name}
              data={formData[editorField?.name]}
              onChange={handleInputChange}
              label={editorField?.label}
              type={'text'}
              component={TextAreaInput}
              style={{
                width: '100%',
              }}
              optional={false}
            />
          </div>
        </div>
      </SliderCard.Body>
      <SliderCard.Footer>
        <Button themeColor={'primary'} onClick={() => handleSlide()}>
          Close
        </Button>
      </SliderCard.Footer>
    </SliderCard>
  );
};

export default DataField;
