import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { APPLICATIONKEY } from '../../../../constants/applicationConstants';
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
const BaseAppKeyDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable'),
);
const AppKeyDataTable = DataTableHoc(BaseAppKeyDataTable);

/**
 * ApplicationKeys main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The ApplicationKeys component.
 */
const ApplicationKeys = ({ isOrganization, orgId }) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const [editItem, setEditItem] = React.useState(null);
  /**
   * show edit content slider
   * @param {*} dataItem
   */
  const editContentSlider = (dataItem) => {
    setEditItem(dataItem);
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          <AppKeyDataTable
            isOrganization={isOrganization}
            orgId={orgId}
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={APPLICATIONKEY}
            dataColumns={dataColumnsArray}
            gridColumnsList={() =>
              GridColumnsList({ editContentSlider, isOrganization })
            }
            dataTableName={APPLICATIONKEY}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${APPLICATIONKEY}.DataGrid.RowLayout`}
            pageLengthConfigName={`${APPLICATIONKEY}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: 'id',
              dir: 'desc',
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            editItem={editItem}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default ApplicationKeys;
