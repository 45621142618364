import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

/**
 * Custom Checkbox Class, show toggle switch or checkbox based on configuration
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Custom Checkbox component.
 */
export const GridFlag = (props) => {
  const isFlagged = props.dataItem[props.field];
  return (
    <td>
      {isFlagged === 1 || isFlagged === true ? (
        <FontAwesomeIcon icon={faCheck} className='grid-flag-icon' />
      ) : (
        <FontAwesomeIcon icon={faTimes} className='grid-flag-icon' />
      )}
    </td>
  );
};

export default GridFlag;
