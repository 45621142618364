import {
  DEFAULT_LIMIT,
  ROW_LAYOUT,
} from '../../../../constants/applicationConstants';

/**
 * GlobalConfigs
 * @typedef GlobalConfigs
 */
export const GlobalConfigs = [`${ROW_LAYOUT}`, `${DEFAULT_LIMIT}`];
export default GlobalConfigs;
