import React from 'react';
import { convertToLocalDate, formatDateStandard } from 'smart-react';
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = () => {
  const dataColumns = [
    {
      field: 'PartialAppKey',
      title: 'App Key',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'Id',
      title: 'Id',
      editable: false,
      show: false,
      minResizableWidth: 150,
      filterable: false,
    },
    {
      field: 'EntityId',
      title: 'EntityId',
      editable: false,
      show: false,
      minResizableWidth: 150,
      filterable: false,
    },
    {
      field: 'Description',
      title: 'Description',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'ExpirationDate',
      title: 'Expiration Date',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{formatDateStandard(props.dataItem.ExpirationDate)}</td>
      ),
    },
    {
      field: 'Created_By',
      title: 'Created By',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'Created_Date',
      title: 'Created Date',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.Created_Date)}</td>
      ),
    },
  ];

  return dataColumns;
};
export default DataColumns;
