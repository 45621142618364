import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { Label } from '@progress/kendo-react-labels';
import { ErrorBoundary } from 'react-error-boundary';
import CodeMirror from '@uiw/react-codemirror';
import { Button } from '@progress/kendo-react-buttons';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import { Loader, ErrorFallback } from 'smart-react';
import './CommandEditor.scss';

/**
 * CommandEditor component
 * CommandEditor component that renders a command basic editor & full screen editor
 * @returns {JSX.Element} - the CommandEditor component
 */
const CommandEditor = ({
  label,
  renderTypes,
  validateForm,
  formData,
  setFormData,
  detailsDataModified,
  showEditor,
  isEditor,
  setIsEditor,
  smHeight,
  lgHeight,
  editorField,
  setEditorField,
  requiredField,
  ...others
}) => {
  /**
   * editor value change
   */
  const onEditorValChange = React.useCallback(
    (val, viewUpdate) => {
      setEditorField({ ...editorField, value: val });
      setFormData((prev) => ({ ...prev, [editorField.name]: val }));
    },
    [editorField, formData],
  );
  /**
   * close the editor screen
   */
  const close = () => {
    setIsEditor(false);
  };
  /**
   * change Screen Mode
   */
  const changeScreenMode = () => {
    setIsEditor(true);
    setEditorField(editorField);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {!isEditor ? (
        <>
          {editorField?.label && (
            <span className='k-floating-label-container k-label'>
              <Label editorId={editorField?.name}>
                {editorField?.label}{' '}
                {requiredField && <span className='required-field'>*</span>}
              </Label>
            </span>
          )}
          <div className='command-input k-d-flex'>
            <div className='k-w-100'>
              <CodeMirror
                value={editorField?.value ?? ''}
                height={smHeight}
                onChange={onEditorValChange}
              />
            </div>

            {detailsDataModified && (
              <span onClick={changeScreenMode} className='open-editor-icon'>
                <FontAwesomeIcon
                  className='icon-full-screen k-mr-1'
                  icon={faExpand}
                />
              </span>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='container mx-auto k-m-3 command-field'>
            <Card className='detail-card'>
              <CardHeader>
                <h6 className='card-title-secondary'>{editorField?.label}</h6>
              </CardHeader>
              <CardBody className='detail-card'>
                <React.Suspense fallback={<Loader />}>
                  <div className='k-border command-syntax-content'>
                    <CodeMirror
                      value={editorField?.value ?? ''}
                      height={lgHeight}
                      onChange={onEditorValChange}
                    />
                    <div className='lcs-action'>
                      <Button themeColor={'primary'} onClick={() => close()}>
                        Close
                      </Button>
                    </div>
                  </div>
                </React.Suspense>
              </CardBody>
            </Card>
          </div>
        </>
      )}
    </ErrorBoundary>
  );
};

export default CommandEditor;
